import type { HandleClientError } from '@sveltejs/kit';
import * as Sentry from '@sentry/sveltekit';
import { dev } from '$app/environment';

Sentry.init({
	environment: dev ? 'development' : 'production',
	dsn: 'https://911d1e6c13588fb392de3fe7bc3d87a1@o4504814498021376.ingest.sentry.io/4505884127199232',

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,

	// Optional: Initialize Session Replay:
	integrations: [
		new Sentry.Replay({
			maskAllText: false,
			blockAllMedia: false
		})
	],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0
});

const myErrorHandler: HandleClientError = ({ error, event }) => {
	console.error('An error occurred on the client side:', error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);
